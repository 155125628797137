import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class BaseService{
    
    constructor(private http: HttpClient) {

    }

    test(controllerAndMeyhod:string) {
      return this.http.get<any>(controllerAndMeyhod);
  }

    get(controllerAndMeyhod:string) {
        return this.http.get<any>(`${environment.appUrl}${controllerAndMeyhod}`);
    }

    post(url:string, body:any) {
        return this.http.post<any>(`${environment.appUrl}${url}`, body);
    }
    //   Put
    put(url:string, body:any) {
        return this.http.put<any>(`${environment.appUrl}${url}`, body); 
    }

    delete(url:string) {
        return this.http.delete<any>(`${environment.appUrl}${url}`);
    }

    export(Controller:string){
        return window.open(this.linkCreator(`${environment.appUrl}${Controller}/Export`));
      }

      exportPost(Controller:string,dto:any){
        return this.http.post(`${environment.appUrl}${Controller}/Export` , dto);
      }

      exportUser(Controller:string,userTypeId:any){
        return window.open(this.linkCreator(`${environment.appUrl}${Controller}/Export/${userTypeId}`));
      }

    linkCreator(link: string, ...param: undefined[]) {
        let res: string = link;
    
        for (let index = 0; index < param.length; index++) {
          const element = param[index];
          res += element + '/';
        }
    
        return res;
      }

      downloadLink(url:string,downloadName:string){
        this.http.get(url, { responseType: 'blob' })
       .subscribe(blob => {
         const link = document.createElement('a');
         link.href =  window.URL.createObjectURL(blob);
         link.download = downloadName;
         link.target="_blank"
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
       });
     }

    getUrlAsBlob(url:string){
      return this.http.get(url, { responseType: 'blob' })
   }


   convertBlobToFile(url:string,blob:Blob,type:string)
   {
    const fileName = url.split("/")[url.split("/").length-1]
    return new File([blob], fileName, { type: type + fileName.split(".")[1] });
   }

      public base64ToBlob(b64Data:any, sliceSize=512) {
        let byteCharacters = atob(b64Data); //data.file there
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
        
            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        
        return new Blob(byteArrays, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        }

        postFile(url: string, body: any) {
          return this.http.post<any>(`${environment.appUrl}${url}`, body, { reportProgress: true, observe: "events" });
  
      }
  
      getFileFromUrl(url: string) {
          return this.http.get(`${url}`, { observe: "response", responseType: 'blob' })
      }
  
      getIcon(type:any) {
          if (type == '.docx' || type == '.doc') return "assets/images/FilesIcons/doc.png";
          else if (type == '.pptx' || type == '.ppt') return "assets/images/FilesIcons/ppt.png";
          else if (type == '.xlsx' || type == '.xls') return "assets/images/FilesIcons/xls.png";
          else if (type == '.pdf') return "assets/images/FilesIcons/pdf.png";
  
          else if (type == '.avi') return "assets/images/FilesIcons/avi.png";
          else if (type == '.dll') return "assets/images/FilesIcons/dll.png";
          else if (type == '.exe') return "assets/images/FilesIcons/exe.png";
          else if (type == '.gif') return "assets/images/FilesIcons/gif.png";
          else if (type == '.html') return "assets/images/FilesIcons/html.png";
          else if (type == '.jpg' || type == '.jpeg') return "assets/images/FilesIcons/jpg.png";
          else if (type == '.mkv') return "assets/images/FilesIcons/mkv.png";
          else if (type == '.mp3') return "assets/images/FilesIcons/mp3.png";
          else if (type == '.mp4') return "assets/images/FilesIcons/mp4.png";
          else if (type == '.mpeg' || type == '.mpg') return "assets/images/FilesIcons/mpg.png";
          else if (type == '.png') return "assets/images/FilesIcons/png.png";
          else if (type == '.psd') return "assets/images/FilesIcons/psd.png";
          else if (type == '.rar') return "assets/images/FilesIcons/rar.png";
          else if (type == '.rmvb') return "assets/images/FilesIcons/rmvb.png";
          else if (type == '.swf') return "assets/images/FilesIcons/swf.png";
          else if (type == '.txt') return "assets/images/FilesIcons/txt.png";
          else if (type == '.wav') return "assets/images/FilesIcons/wav.png";
          else if (type == '.zip') return "assets/images/FilesIcons/zip.png";
          else return "assets/images/FilesIcons/other.png";
      }

     public TranslateTree(node: any,lang:any) {
        node.label=lang == 'ar' ?node.nameAr:node.nameEn;
        if (node.children !=null && node.children.length > 0) {
        node.children.forEach((childNode:any)=> {
          this.TranslateTree(childNode,lang);
        });
      }
    }
}
